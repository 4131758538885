@import "~app/mixins";

.radio {
  position: relative;
  cursor: default;
  user-select: none;
  padding: 3px 0;
}

.disabled {
  opacity: 0.5;
}

.input {
  opacity: 0;
  z-index: -1;
  position: absolute;

  &:checked ~ .iconRadioChecked {
    display: block;
  }

  &:checked ~ .iconRadio {
    display: none;
  }

  &:checked ~ .arrow {
    display: block;
  }
}

.iconRadio,
.iconRadioChecked {
  width: 15px;
  height: 15px;
}

.iconRadioChecked {
  display: none;
}

.switch {
  height: 15px;
  display: flex;
  align-items: center;

  .label {
    color: $color-grey-1;
    font-size: 12px;
    padding-left: 10px;
  }
}

.arrow {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: 6px;
  border-style: solid;
  border-width: 0 8px 8px;
  border-color: transparent;

  @include primary-border-bottom-color();

  &:after {
    content: '';
    position: absolute;
    top: 1px;
    left: -8px;
    border-style: solid;
    border-width: 0 8px 8px;
    border-color: transparent transparent $color-cyan transparent;
  }
}
